<template>
  <div class="content-wrapper">
    <tender-file-modal ref="tenderFileModal"></tender-file-modal>
    <b-tabs
      content-class="col-12 col-md-9 mt-1 mt-md-0 tab-content-container"
      pills
      nav-wrapper-class="background-nav"
    >
      <b-tab @click="active = 'new'">
        <template #title>
          {{ $t("Generic.New") }}
        </template>
      </b-tab>
      <b-tab @click="active = 'active'">
        <template #title>
          {{ $t("Generic.Active") }}
        </template>
      </b-tab>
    </b-tabs>
    <b-modal id="booststrapmodal" @hidden="showComment = false" :visible="showComment">
      <b-row>{{ commetModalText }} </b-row>

      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            class="float-right"
            @click="showComment = false"
          >
            {{ $t("Generic.CloseBtn") }}
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-row class="loader-row">
      <loader class="loader" ref="loader"></loader>
    </b-row>
    <b-card v-if="active == 'new'" class="order-overview">
      <b-row class="tender" v-for="tender in openTenders" :key="tender.id">
        <span class="tender-ref">
          <span>
            {{ $t("SupplierTender.Ref") }} - {{ tender.tenderRefId }} -
            {{ tender.concernName }}
          </span>
          <span v-if="tender.endDate.indexOf('0001-01-01') == -1">
            {{ $t("SupplierTender.TenderEnd") }} -
            {{ format_date(tender.endDate) }}
          </span>
        </span>
        <div
          class="locations"
          v-for="location in getLocationForTender(tender)"
          :key="location.id"
        >
          <div class="location-header">
            <span>{{ location.name }}</span>
            <span
              >{{ location.address }}, {{ location.zip }}
              {{ location.city }}</span
            >
          </div>
          <div
            class="tenderItems"
            v-for="tenderitem in getTendersItemsForlocation(location, tender)"
            :key="tenderitem.id"
          >
            <b-col cols="12" sm="2">
              <label>
                {{ $t("Generic.Name") }}
              </label>
              <input
                disabled
                type="text"
                class="form-control"
                v-model="tenderitem.name"
              />
            </b-col>
            <b-col cols="12" sm="2">
              <label>
                {{ $t("Generic.Type") }}
              </label>
              <input
                disabled
                type="text"
                class="form-control"
                v-model="tenderitem.containerType"
              />
            </b-col>
            <b-col cols="12" sm="1">
              <label>{{ $t("Generic.Size") }}</label>
              <input
                disabled
                type="text"
                :value="
                  tenderitem.containerSize +
                  ' ' +
                  sizeUnits[tenderitem.containerSizeUnit]
                "
                class="form-control"
              />
            </b-col>
            <b-col cols="12" sm="1">
              <label>
                {{ $t("Generic.AnnualTons") }}
              </label>
              <input
                disabled
                type="number"
                class="form-control"
                v-model="tenderitem.annualTons"
              />
            </b-col>

            <b-col cols="12" sm="1">
              <label>
                {{ $t("Generic.AnnualEmpties") }}
              </label>
              <input
                disabled
                type="number"
                class="form-control"
                v-model="tenderitem.annualEmpties"
              />
            </b-col>

            <b-col cols="12" sm="1">
              <label>
                {{ $t("Generic.RentCost") }}
              </label>
              <input
                type="number"
                class="form-control"
                v-model="tenderitem.rentCost"
              />
            </b-col>

            <b-col cols="12" sm="1">
              <label>
                {{ $t("Generic.CollectionCost") }}
              </label>
              <input
                type="number"
                class="form-control"
                v-model="tenderitem.collectionCost"
              />
            </b-col>

            <b-col cols="12" sm="1">
              <label>
                {{ $t("Generic.CostPrTons") }}
              </label>
              <input
                type="number"
                class="form-control"
                v-model="tenderitem.costPrTons"
              />
            </b-col>

            <b-col cols="12" sm="1">
              <label>
                {{ $t("Generic.GainPrTons") }}
              </label>
              <input
                type="number"
                class="form-control"
                v-model="tenderitem.gainPrTons"
              />
            </b-col>

            <b-col cols="12" sm="1" class="actions">
              <feather-icon
                v-if="tenderitem.comment"
                @click="displayComment(tenderitem)"
                class="ml-1"
                icon="AlignJustifyIcon"
                size="18"
                v-b-tooltip.hover.top="$t('Generic.Comment')"
              />
              <feather-icon
                v-if="tender.tenderFiles.length"
                @click="openTenderFileModal(tender)"
                class="ml-1"
                icon="FileTextIcon"
                size="18"
                v-b-tooltip.hover.top="$t('Generic.Files')"
              />
              <feather-icon
                @click="saveTenderOffer(tenderitem)"
                :class="canSubmit(tenderitem) ? '' : 'disbaled'"
                class="ml-1"
                icon="SaveIcon"
                size="18"
                v-b-tooltip.hover.top="
                  canSubmit(tenderitem)
                    ? $t('Generic.Save')
                    : $t('Generic.MissingValueValidation')
                "
              />
            </b-col>
          </div>
        </div>
      </b-row>
    </b-card>
    <b-card v-if="active == 'active'" class="order-overview">
      <b-row class="tender" v-for="tender in activeTenders" :key="tender.id">
        <span class="tender-ref">
          <span>
            {{ $t("SupplierTender.Ref") }} - {{ tender.tenderRefId }} -
            {{ tender.concernName }}
          </span>
          <span v-if="tender.endDate.indexOf('0001-01-01') == -1">
            {{ $t("SupplierTender.TenderEnd") }} -
            {{ format_date(tender.endDate) }}
          </span>
        </span>
        <div
          class="locations"
          v-for="location in getLocationForTender(tender)"
          :key="location.id"
        >
          <div class="location-header">
            <span>{{ location.name }}</span>
            <span
              >{{ location.address }}, {{ location.zip }}
              {{ location.city }}</span
            >
          </div>

          <b-table
            :items="getTendersItemsForlocation(location, tender)"
            :fields="tableContent.fields"
            responsive
            primary-key="id"
            show-empty
            :empty-text="$t('TenderView.TableEmptyText')"
            class="position-relative invoiceListTable"
            :sort-by.sync="tableContent.sortBy"
            :sort-desc.sync="tableContent.sortDesc"
          >
            <template #cell(action)="data">
              <feather-icon
                v-if="data.item.comment"
                @click="displayComment(data.item)"
                class="ml-1"
                icon="AlignJustifyIcon"
                size="18"
                v-b-tooltip.hover.top="$t('Generic.Comment')"
              />
              <feather-icon
                v-if="tender.tenderFiles.length"
                @click="openTenderFileModal(tender)"
                class="ml-1"
                icon="FileTextIcon"
                size="18"
                v-b-tooltip.hover.top="$t('Generic.Files')"
              />
            </template>
          </b-table>
        </div>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BSpinner,
  BRow,
  BCardTitle,
  BTable,
  BAvatar,
  BTabs,
  BTab,
  BFormCheckbox,
  BCol,
  BButton,
  BModal,
  BBadge,
} from "bootstrap-vue";
import IconBadge from "@/components/IconBadge.vue";
import { VBTooltip } from "bootstrap-vue";
import ResourceService from "@/services/base/resource.service";
import loader from "@/components/layout/loader.vue";
import TenderFileModal from "@/components/modals/tenders/TenderFileModal.vue";

export default {
  components: {
    BCard,
    BSpinner,
    BRow,
    BCardTitle,
    BTable,
    BAvatar,
    BTabs,
    BTab,
    BFormCheckbox,
    BCol,
    BModal,
    BButton,
    BBadge,
    IconBadge,
    VBTooltip,
    loader,
    TenderFileModal,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  async mounted() {
    this.service = new ResourceService("suppliers");
    if (this.$route.params.id != undefined) {
      this.supplierId = "/" + this.$route.params.id;
    }

    let response = await this.service.get(`${this.supplierId}/tenders`);
    if (response.data.data) {
      this.tenders = response.data.data;
    }
  },
  data() {
    return {
      supplierId: '',
      active: "new",
      sortBy: "createdDate",
      baseUrl: process.env.VUE_APP_APIURL,
      sortDesc: true,
      showComment: false,
      commetModalText: "",
      tenders: [],
      sizeUnits: {
        Cubic: "m3",
        Litre: "L",
      },
    };
  },
  methods: {
    displayComment(tenderItem) {
      this.showComment = true;
      this.commetModalText = tenderItem.comment;
    },
    async saveTenderOffer(tenderItem) {
      this.$refs.loader.loading();

      let requestObj = {
        tenderItemId: tenderItem.id,
        rentCost: Number.parseFloat(tenderItem.rentCost),
        collectionCost: Number.parseFloat(tenderItem.collectionCost),
        costPrTons: Number.parseFloat(tenderItem.costPrTons),
        gainPrTons: Number.parseFloat(tenderItem.gainPrTons),
      };

      await this.service.post(`${this.supplierId}/tenderOffer`, requestObj);

      this.$refs.loader.loadCompelete();
    },
    async openTenderFileModal(tender) {
      await this.$refs.tenderFileModal.open(tender, false);
    },
    canSubmit(tenderItem) {
      return (
        tenderItem.rentCost != undefined &&
        tenderItem.collectionCost != undefined &&
        tenderItem.costPrTons != undefined &&
        tenderItem.gainPrTons != undefined
      );
    },
    getLocationForTender(tender) {
      let locationsCollection = [];
      tender.items.forEach((tenderItem) => {
        let location = locationsCollection.find(
          (l) => l.id == tenderItem.location.id
        );
        if (location == undefined)
          locationsCollection.push(tenderItem.location);
      });
      return locationsCollection;
    },
    getTendersItemsForlocation(location, tender) {
      return tender.items.filter((t) => t.location.id == location.id);
    },
  },
  computed: {
    openTenders() {
      return this.tenders.filter((t) => t.tenderStatus == "Open");
    },
    activeTenders() {
      return this.tenders.filter((t) => t.tenderStatus == "Done");
    },
    tableContent() {
      return {
        sortDesc: true,
        sortBy: "state",
        fields: [
          {
            key: "name",
            label: this.$t("Generic.Name"),
            sortable: false,
          },
          {
            key: "containerType",
            label: this.$t("Generic.Type"),
            sortable: true,
          },

          {
            key: "containerSize",
            label: this.$t("Generic.Size"),
            sortable: true,
          },
          {
            key: "annualTons",
            label: this.$t("Generic.AnnualTons"),
            sortable: true,
          },
          {
            key: "annualEmpties",
            label: this.$t("Generic.AnnualEmpties"),
            sortable: true,
          },
          {
            key: "rentCost",
            label: this.$t("Generic.RentCost"),
            sortable: true,
          },
          {
            key: "collectionCost",
            label: this.$t("Generic.CollectionCost"),
            sortable: true,
          },
          {
            key: "costPrTons",
            label: this.$t("Generic.CostPrTons"),
            sortable: true,
          },
          {
            key: "gainPrTons",
            label: this.$t("Generic.GainPrTons"),
            sortable: true,
          },
          {
            key: "action",
            label: "",
            sortable: false,
          },
        ],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.loader-row {
  position: relative;
  width: 100%;
  height: 40px;
}
.tender {
  margin-bottom: 20px;
  padding: 10px;
  flex-direction: column;
  box-shadow: 0 5px 20px 0 rgb(34 41 47 / 10%);
  .locations {
    padding: 10px;

    .location-header {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid #ccc;
      span {
        &:nth-child(1) {
          font-size: 1.1rem;
        }
        &:nth-child(2) {
          font-size: 0.8rem;
        }
      }
    }
  }
  &:last-of-type {
    margin-bottom: 0;
  }

  > .tender-ref {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-weight: bold;
    border-bottom: 1px solid #ccc;

    span {
      width: 49%;
      &:nth-child(2) {
        text-align: end;
      }
    }
  }
  .tenderItems {
    .actions {
      svg {
        &.disbaled {
          cursor: default;
          opacity: 0.3;
        }
        cursor: pointer;
      }
      display: flex;
      align-items: center;
    }
    display: flex;
    width: 100%;
  }
}
.loader {
  position: absolute;
  top: 5px;
  right: 5px;
}
</style>
