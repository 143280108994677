<template>
  <div>
    <b-modal
      id="booststrapmodal"
      :title="showEdit ? $t('TenderFileModal.Title') : $t('Generic.Files')"
      centered
      :visible="visible"
      @hidden="handleModalHidden"
      :no-close-on-backdrop="true"
      :hide-header-close="true"
      size="lg"
    >
      <div class="list">
        <div v-for="file in tender.tenderFiles" :key="file.path">
          <a
            @click.prevent="
              clickedDownload(imageBaseUrl + '/images/' + file.path, file.name)
            "
          >
            {{ file.name }}
          </a>
          <feather-icon
            v-if="showEdit"
            @click="removeFile(file.id)"
            class="ml-1"
            icon="DeleteIcon"
            size="18"
            v-b-tooltip.hover.top="$t('Generic.DeleteBtn')"
          />
        </div>
      </div>
      <template #modal-footer>
        <div class="w-100">
          <input
            v-if="showEdit"
            type="file"
            ref="fileupload"
            @change="setFiles"
            multiple
          />
          <b-button
            v-if="showEdit"
            variant="primary"
            class="float-right"
            :disabled="uploading"
            @click="handleSubmit"
          >
            {{ uploading ? $t("Generic.Uploading") : $t("Generic.UploadBtn") }}
          </b-button>
          <b-button
            variant="light"
            class="float-right mr-2"
            @click="handleModalHidden"
          >
            {{ $t("Generic.CloseBtn") }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { BModal, BButton, BAvatar, BTable, BFormTextarea } from "bootstrap-vue";
import FlexTable from "@/components/flex-table/FlexTable.vue";
import WeekdayPicker from "@/components/forms/WeekdayPicker.vue";
import IconBadge from "@/components/IconBadge.vue";
import { VBTooltip } from "bootstrap-vue";
import TenderService from "@/services/economics/tender-service";

export default {
  components: {
    BModal,
    BButton,
    FlexTable,
    BAvatar,
    BTable,
    BFormTextarea,
    WeekdayPicker,
    IconBadge,
    VBTooltip,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  created() {
    this.tenderService = new TenderService();
  },
  data() {
    return {
      imageBaseUrl: process.env.VUE_APP_APIURL,
      uploading: false,
      tenderService: null,
      loading: false,
      showEdit: true,
      files: [],
      visible: false,
      tender: {},
    };
  },
  methods: {
    async clickedDownload(filepath, fileName) {
      try {
        const response = await fetch(filepath);
        const blob = await response.blob();
        const url = await URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } catch (err) {
        console.log({ err });
      }
    },
    async open(tender, showEdit = true) {
      this.showEdit = showEdit;
      this.visible = true;
      this.tender = tender;
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    async removeFile(fileId) {
      await this.tenderService.delete("/" + this.tender.id + "/file/" + fileId);
      let file = this.tender.tenderFiles.find((t) => t.id == fileId);
      let index = this.tender.tenderFiles.indexOf(file);
      this.tender.tenderFiles.splice(index, 1);
    },
    setFiles(event) {
      this.files = event.target.files;
    },
    handleModalHidden() {
      this.visible = false;
    },
    async handleSubmit() {
      this.uploading = true;

      var form_data = new FormData();
      Array.from(this.files).forEach((file) => {
        form_data.append("files", file, file.name);
      });

      await this.tenderService.post("/" + this.tender.id + "/files", form_data);
      this.resolvePromise(true);
      this.uploading = false;
      this.handleModalHidden();
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
.list {
  display: flex;
  flex-wrap: wrap;
  > div {
    position: relative;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0 5px 20px 0 rgb(34 41 47 / 10%);
    margin-bottom: 10px;
    svg {
      position: absolute;
      right: -1px;
      top: -6px;
      cursor: pointer;
    }
  }
}
</style>